import React from 'react';

import "./teacherbar.css"

export default function TeacherBar() {
    return(
        <div className='teacherbar-container'>
            <div className='teacherbar-content'>
                <span className='teacherbar-title'>선생님 목록</span>
            </div>
        </div>
    )
}