import React from 'react';

import "./studentbar.css"

export default function StudentBar() {
    return(
        <div className='studentbar-container'>
            <div className='studentbar-content'>
                <span className='studentbar-title'>학생 목록</span>
            </div>
        </div>
    )
}